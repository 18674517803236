/* ----------------------------------------
    Variables
---------------------------------------- */
/* Colours */
$primary: #fafdcb;
$primary-tint: #aee7e8;
$primary-dark: #248ea9;
$secondary: #6c7b95;
$secondary-dark: #464159;

$dash-primary: #00adb5;
$design-primary: #2e557a;

$gradient-primary: linear-gradient(135deg, $primary 10%, $primary-tint, $primary-dark);
$gradient-light: linear-gradient(135deg, #fff, whitesmoke);
$gradient-dark: linear-gradient(135deg, $secondary, $secondary-dark);

/* Typography */
$poppins: "Poppins", sans-serif;
$opensans: "Barlow Semi Condensed", sans-serif;
$nav-size: 1.2rem;

/* Sizing */
$padding-small: 1rem;
$padding-medium: 2rem;
$padding-large: 4rem;

$translate-top: -0.61em;
$translate-bottom: 0.6em;
$translate-box: 1.95em;
$translate-mouse: 0em;

/* ----------------------------------------
    Mixins
---------------------------------------- */
@mixin container($bg-color, $border-color) {
  display: flex;
  flex-direction: column;

  height: 100vh;
  padding: $padding-large;
  background: $bg-color;
  z-index: -1;

  header,
  footer {
    height: $padding-large;
    border-left: solid $border-color 1px;
    border-right: solid $border-color 1px;
  }
}

@mixin border($translate-y, $left, $right, $border-color) {
  display: flex;
  align-items: center;
  user-select: none;
  transform: translate(0, $translate-y);

  &::before,
  &::after {
    content: "";
    display: inline-block;
    border-top: solid $border-color 1px;
  }
  &::before {
    width: $left;
    margin-right: $padding-medium;
  }
  &::after {
    width: $right;
    margin-left: $padding-medium;
  }
}

@mixin heading($font) {
  font-family: $font;
  text-transform: uppercase;
}

@mixin textbox($bar-color) {
  .bar {
    display: inline-block;
    min-width: 4vw;
    margin: 0.5em 1.5em 0 0;
    border-top: solid 5px $bar-color;
  }
  .content {
    display: flex;
    padding-top: $padding-medium;
  }
}

@mixin gradientText($gradient-color) {
  background: $gradient-color;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin backToTop() {
  position: absolute;
  top: calc(#{$padding-large} / 2);
  right: calc(#{$padding-large} / 2);
  transform: translate(0, 0); // Enables wipe animation
}
