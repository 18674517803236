/* ----------------------------------------
    Desktop Queries
---------------------------------------- */
// Set font size for desktop
@media only screen and (min-width: 768px) {
  html {
    font-size: calc(0.5vmax + 10px);
  }
}

// Makes better use of taller desktops
@media only screen and (max-aspect-ratio: 3/2) {
  #home {
    article {
      width: 60%;
    }
  }
  #dashpoint,
  #design {
    article {
      & > div:first-child + div {
        padding-left: 0;
      }
    }
  }

  // Rearrange contact form if height is suitable
  @media only screen and (min-height: 900px), only screen and (orientation: portrait) {
    #contact {
      article {
        flex-direction: column;
        margin-top: $padding-small;
      }
      form {
        align-self: auto !important;
        padding-top: $padding-medium;
        padding-left: calc(5vw + #{$padding-small});
      }
      input,
      textarea {
        min-width: 50vmax;
      }
    }
  }
}
// Approaching portrait orientation or landscape mobiles
@media only screen and (max-aspect-ratio: 11/10), screen and (max-width: 849px) and (orientation: landscape) {
  #home {
    article {
      width: calc(100% - 5vw - #{$padding-small});
    }
  }
  #dashpoint,
  #design {
    article {
      flex-direction: column;
      height: 70vh;
      width: 100%;
      margin-top: 0;

      & > div:first-child {
        width: calc(100% - 5vw - #{$padding-small});
      }
      & > div:first-child + div {
        min-height: 0;
        padding-top: $padding-medium;
        text-align: center;
      }
    }
    aside {
      padding-top: $padding-medium;
    }
    img {
      height: 100%;
      width: auto;
      margin: 0;
    }
  }
}

/* ----------------------------------------
    Mobile Queries
---------------------------------------- */
// Any portrait mobile or landscape mobile below 850px (to distinguish from portrait tablets)
@media only screen and (max-width: 767px), screen and (max-width: 849px) and (orientation: landscape) {
  html {
    font-size: 18px;
    overflow-x: hidden;
  }
  section {
    padding: $padding-medium !important;
  }
  header {
    display: none !important;
  }

  #home {
    article {
      width: 100%;
    }
    h1,
    h2,
    p {
      text-align: center;
    }
    h1 {
      font-size: 4rem;
    }
    h2 {
      padding: 0 $padding-medium;
    }
    footer {
      display: none;
    }
  }
  #dashpoint,
  #design {
    background: #f5f5f5;

    article {
      height: auto;

      & > div:first-child {
        width: 105%;
      }
    }
    h2,
    h3,
    p {
      text-align: left;
    }
    footer {
      height: auto;
      margin-bottom: $padding-medium;
      border: 0;

      & > * {
        justify-content: center;

        &::before,
        &::after {
          border-top: 0;
        }
        & > a {
          margin: 0 $padding-small !important;
          padding: 0 $padding-small;
        }
        & > span {
          display: none;
        }
      }
    }
  }
  #contact {
    form {
      padding-left: 0 !important;

      div,
      button {
        margin: 0 auto !important;
      }
    }
    input,
    textarea {
      min-width: 100% !important;
    }
    h2 {
      font-size: 3.5rem;
    }
    footer {
      border: 0;

      & > * {
        &::before,
        &::after {
          border-top: 0;
        }
      }
    }
  }

  .back-to-top,
  .bar {
    display: none !important;
  }
}
